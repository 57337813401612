<template lang="pug">
	.box(:class="parentClass", :style="parentStyle")
		router-link(:to="{ name: contents.browsers[0].module, params: { slug: pageSlug }, query: { backTo } }", v-if="!contents.content.isExternal && contents.browsers") {{ contents.browsers[0].name }}
		a(:href="contents.content.link", target="_blank", rel="noopener noreferrer", v-else-if="contents.content.isExternal && contents.content.link")
			| {{ contents.content.title }}
			FontAwesomeIcon(:icon="icons.faArrowUpRightFromSquare")
		p(v-else) {{ contents.content.title }}
		FontAwesomeIcon(:icon="icons.faAngleRight", v-if="!contents.content.isExternal && contents.browsers").icon-link
		component-organograma-box(v-for="item, index in contents.children", :contents="sanitizeBox(item)", :backTo="backTo", :key="index + '-' + sanitizeBox(item).content.title").inner-box
</template>

<script>
import { props } from '@/mixins/components'
import { faArrowUpRightFromSquare, faAngleRight } from '@fortawesome/free-solid-svg-icons'

export default {
  name: 'component-organograma-box',
  props: Object.assign({}, props, {
    backTo: {
      type: String,
      required: true
    }
  }),
  data () {
    return {
      icons: {
        faArrowUpRightFromSquare,
        faAngleRight
      }
    }
  },
  computed: {
    browsers () {
      return this.contents.browsers
    },
    pageSlug () {
      if (this.browsers) { return this.browsers[0].slug }
      return null
    },
    parentClass () {
      return {
        onlyThree: this.contents.children ? this.contents.children.length == 3 : false,
        alone: this.contents.content.itemType == 'alone',
        group: this.contents.content.itemType == 'group'
      }
    },
    parentStyle () {
      return {
        backgroundColor: this.contents.content.color || null
      }
    }
  },
  methods: {
    sanitizeBox (item) {
      return {
        children: item.children,
        browsers: item.browsers || null,
        content: {
          title: item.listName || item.pageTitle || null,
          type: item.type,
          itemType: item.itemType || null,
          color: item.color || null,
          isExternal: item.isExternal || null,
          link: item.link
        }
      }
    }
  }
}
</script>

<style lang="stylus" scoped src="./OrganogramaBox.styl"></style>
